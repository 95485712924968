import '../libs/Magnific-Popup-master/dist/magnific-popup';
require('../libs/Magnific-Popup-master/dist/jquery.magnific-popup.min.js');
// Загрузка стилей

import '../style/loader';
import $ from 'jquery';
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

// Главный слайдер
const mainSlider = document.querySelector('.main-slider')
if (mainSlider) {
    
    var slider = tns({
        container: mainSlider,
        items: 1,
        slideBy: 'page',
        autoplay: true,
        autoplayButtonOutput: false,
        controls: false,
        mouseDrag: true
    });

    document.querySelector('.controls-trigger_left').onclick = function () {
        slider.goTo('prev');
    };
    
    document.querySelector('.controls-trigger_right').onclick = function () {
        slider.goTo('next');
    };
}

// Сладйер клиентов
const clientList = document.querySelector('.clients-row');
if (clientList) {
    var partnersSlider = tns({
        container: clientList,
        items: 1,
        autoplay: true,
        autoplayButtonOutput: false,
        controls: false,
        mouseDrag: true,
        responsive: {
          640: {
            edgePadding: 20,
            gutter: 20,
            items: 1
          },
          700: {
            gutter: 20,
            items: 2
          },
          900: {
            items: 4
          }
        }
      });

    document.querySelector('.slider-sm-prev').onclick = function () {
        partnersSlider.goTo('prev');
    };
    
    document.querySelector('.slider-sm-next').onclick = function () {
        partnersSlider.goTo('next');
    };
    
}

const mobileMenu = $('.mobile-menu')

mobileMenu.on('click', function() {
  mobileMenu.next().slideToggle()
})

$('.popup-gallery').each(function() { // the containers for all your galleries
  $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,4], // Will preload 0 - before current, and 1 after the current image
        tCounter: '<span class="mfp-counter">%curr% из %total%</span>' // markup of counter
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('title');
        }}
  });
});

$('.call-modal').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

$('.brewing-modal').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

$(document).ready(function() {
  $(".product-page-nav ul li a").click(function() {
    var elementClick = $(this).attr("href")
    var destination = $(elementClick).offset().top;
    var space = 50;
    $("html:not(:animated),body:not(:animated)").animate({
      scrollTop: destination - space
    }, 800);
    return false;
  });
});

$(document).ready(function() {
  $('.product-page-nav ul li a').on('click', function(e) {
    e.preventDefault()
    let panel = $(this).parent().parent();
    panel.find('a.active').removeClass('active');
    $(this).addClass('active')
  })
})

$('.work-inner').each(function() { // the containers for all your galleries
  $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,4], // Will preload 0 - before current, and 1 after the current image
        tCounter: '<span class="mfp-counter">%curr% из %total%</span>' // markup of counter
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('title');
        }}
  });
});